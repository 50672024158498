* {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: auto;
}
html {
  font-size: 10pt;
  line-height: 1.4;
  font-weight: 400;
  font-family: "Roboto Mono", monospace;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
  background-image: url("christine-siracusa-vzX2rgUbQXM-unsplash.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
ul {
  list-style-type: none;
  margin: 0 auto 1%;
  padding: 0;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 80%,
    rgba(245, 245, 245, 1) 100%
  );
  width: 90%;
}

button {
  width: 60%;
  max-width: 200px;
  height: 15%;
  border-radius: 15%;
  margin: 25px auto 0;
  font-size: 16px;
  display: block;
}

button:hover {
  cursor: pointer;
  box-shadow: 0 10px 6px -6px #777;
}
button:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
  border-color: -moz-use-text-color #ffffff #ffffff -moz-use-text-color;
  border-style: none solid solid none;
  border-width: medium 1px 1px medium;
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.07);
  transform: translateY(4px);
}

label {
  text-decoration: underline;
  font-size: 1.2rem;
}
select {
  margin: 3%;
  background-color: white;
  font-size: 2rem;
}
li > a {
  font-size: 1.4rem;
}
a {
  font-size: 1.2rem;
  color: cornflowerblue;
  text-decoration: none;
}
a:visited {
  color: cornflowerblue;
}
a:hover {
  color: navy;
}

h1,
h2 {
  display: inline;
}

nav {
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 5%,
    rgba(245, 245, 245, 1) 100%
  );
  width: 80%;
  margin: 0 auto;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.mobile {
  display: inherit;
}
.desk {
  display: none;
}

.sign-in-message {
  width: 60%;
}
.page-message {
  width: 80%;
}

.rotationLists {
  text-decoration: underline;
}

#rotationDiv {
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 55%,
    rgba(245, 245, 245, 1) 100%
  );
  width: 80%;
  margin: 0 auto;
}

h1.title {
  width: 80%;
  font-size: 4rem;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 70%,
    rgba(245, 245, 245, 0.48363095238095233) 100%
  );
  margin: 4% auto;
}

.matchText {
  font-size: 20px;
}

.App {
  text-align: center;
  width: 80vw;
  border: solid 1px black;
  border-radius: 5%;
  margin: 10vh auto;
  box-shadow: 0 7px 4px #777, 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 30px 0 20px;
  background-color: #f5f5f5;
  opacity: 0.9;
}

.App > * {
  margin: 0 auto;
}

.App > section {
  width: 80%;
  margin: 0 auto;
}

.label-container {
  border: solid 1px #ccc;
  border-radius: 15%;
  margin: 50px auto 8px;
  padding: 5px 8px;
  position: relative;
  width: 300px;
  background-color: white;
}

.label-container input {
  border: none;
  font-size: 24px;
  outline: 0;
  padding: 30px 20px 5px;
  width: fit-content;
  margin: 0 auto;
}

.label-container label {
  font-size: 16px;
  position: absolute;
  transform-origin: top left;
  transition: all 0.1s ease-in-out;
}

.label-container input #mealName {
  width: 10%;
  margin: 0 auto;
}
.mealSpan {
  font-size: 24px;
  margin: 0 10px;
}
.mealName {
  font-size: 3rem;
  margin: 0;
  grid-area: mealName;
}
.greeting {
  display: block;
}

.rotation {
  width: 40vw;
  text-align: right;
  grid-area: rotation;
}
.rotationSpan {
  grid-area: rotationSpan;
}
.ateDate {
  grid-area: ateDate;
}
.dateEaten {
  grid-area: dateEaten;
}
.navSpan {
  display: none;
}

button > a {
  text-decoration: none;
  color: black;
}

button > a:visited {
  color: black;
}
.longList {
  width: 60%;
}
.mediumList {
  width: 80%;
}
.welcomeText {
  display: block;
}

div.error {
  width: 80%;
  margin: 0 auto;
}
.welcomeText {
  width: 80%;
  margin: 0 auto;
}

.label-container {
  margin: 30px auto 8px;
  padding: 5px 8px;
  width: 200px;
}
.label-container input {
  font-size: 16px;
  width: 180px;
}
.label-container label {
  font-size: 12px;
}

@media screen and (min-width: 720px) {
  ul {
    width: 100%;
  }

  button {
    display: inline;
    margin: 1%;
  }
}

@media screen and (min-width: 810px) {
  .mobile {
    display: none;
  }
  .desk {
    display: inherit;
  }

  h1.title {
    width: 90%;
  }
}

@media screen and (min-width: 1070px) {
  ul li {
    display: inline-block;
    margin: 1vw;
  }
  .navSpan {
    display: inline;
  }
}
